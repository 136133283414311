import type { ShouldRevalidateFunction } from '@remix-run/react'

export const ignoreDialogRevalidation: ShouldRevalidateFunction = ({
  currentUrl,
  nextUrl,
  defaultShouldRevalidate,
}) => {
  const currentParams = new URLSearchParams(currentUrl.search)
  const nextParams = new URLSearchParams(nextUrl.search)

  if (
    currentUrl.pathname === nextUrl.pathname &&
    currentParams.get('dialog') !== nextParams.get('dialog')
  ) {
    return false
  }

  return defaultShouldRevalidate
}
